import React from "react"
import * as S from "./styles"

const TermsOfUsePage = ({ children }) => {
  return (
    <div css={S.TermsOfUsePageStyle}>{children}</div>
  )
}

export default TermsOfUsePage
