import { css } from "@emotion/react"

export const TermsOfUsePageStyle = css`
  .container {
    width: 100%;
    padding: 30px;
    overflow: auto;

    h1 {
      font-size: 1.5rem;
      margin: 0 0 0.5rem;
      border: 0;
      padding: 0;
    }

    p {
      font-size: 16px;
      font-size: 1rem;
      line-height: 32px;
      margin-bottom: 30px;
    }

    .institutional {
      padding: 30px;

      ol,
      ul {
        margin: 0 0 1rem;
        padding-inline: 2.5rem;
        li {
          margin: 0 0 0.5rem;
          p {
            margin-bottom: 0;
          }
        }
      }

      table {
        border-collapse: collapse;
        margin-bottom: 25px;

        thead,
        tbody {
          margin: 0;
          padding: 0;
          list-style-type: none;
          box-sizing: border-box;

          tr {
            background-color: #eef3f4;

            th,
            td {
              border: 1px solid #e2ebed;
              padding: 20px 30px;
              font-size: 14px;
              font-size: 0.875rem;
              text-align: left;
              color: #1a1a1a;
              white-space: pre-wrap;

              p {
                font-size: 16px;
                font-size: 1rem;
                line-height: 32px;
                margin-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
`
