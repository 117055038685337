import DefaultLayout from "../../layout/DefaultLayout"
import TermsOfUsePage from "@mkt/ui/templates/TermsOfUsePage"
import useStoryblok from "@mkt/utils/storyblok/useStoryblok"
import { render } from "storyblok-rich-text-react-renderer"

const TermsOfUse = ({ pageContext, location }) => {
  const data = useStoryblok(pageContext.content)
  const content = data?.content || pageContext.content

  return (
    <DefaultLayout pageContext={pageContext} location={location}>
      <TermsOfUsePage>
        <div className="container">
          <h1>{content?.title}</h1>
          <div className="institutional">{render(content.text_context)}</div>
        </div>
      </TermsOfUsePage>
    </DefaultLayout>
  )
}

export default TermsOfUse
